class LegalConsentSettings {

    domLegalWrapper = null;

    constructor() {
        window.addEventListener('DOMContentLoaded', () => {
            this.setupLegalIframe();
            document.getElementById('allowDataTracking')
                .addEventListener('click', () => this.acceptAll(), false);
            document.getElementById('denyDataTracking')
                .addEventListener('click', () => this.declineAll(), false);
            document.getElementById('denyDataTrackingMobile')
                .addEventListener('click', () => this.declineAll(), false);
        }, false);

        window.onload = () => { this.domLegalWrapper.urlChanged(window.location.href) }
    }

    initLegal() {
        this.onConsentChange();
        this.onBannerChange();
        this.onBxclidChange();
    }

    setupLegalIframe() {
        this.domLegalWrapper = new window.legalWrapper.LegalWrapper({
            debug: false,
            domToLoadLegal: '#legal',
            baseUrl: `${env.legalWrapperIframeUrl}`,
            config: {
                domain: `${env.domainName}`,
                apiBasePath: `${env.apiUrl}`,
                appName: `${env.appName}`
            },
            onConfigLoaded: () => {
                this.initLegal();

                this.domLegalWrapper.getConsentValue((value) => {
                    window.consent = value;
                });
                this.domLegalWrapper.getBannerValue((showBanner) => {
                    this.bannerDisplay(showBanner);
                });
            },
        });
    }

    bannerDisplay(display) {
        const dataTrackingBanner = document.getElementById('data-tracking-banner');
        dataTrackingBanner.hidden = !display;
    }

    acceptAll() {
        this.domLegalWrapper.acceptAll();
    }

    declineAll() {
        this.domLegalWrapper.declineAll();
    }

    onConsentChange() {
        this.domLegalWrapper.onConsentChange(({oldValue, newValue}) => {
            window.consent = newValue;
        });
    }

    onBannerChange() {
        this.domLegalWrapper.onBannerChange((showBanner) => {
            this.bannerDisplay(showBanner);
        });
    }

    onBxclidChange() {
        this.domLegalWrapper.onBxclidChange(({oldValue, newValue})  => {
            if(newValue) {
                this.domLegalWrapper.sendConversionEvent('START_CONVERSION', {});
            }
        });
    }
}

export function initLegalConsentSettings() {
    return new LegalConsentSettings();
}
