class CarriersFilter {

    allCarriers = [
        {
            "code": "fedx",
            "type": ["express", "home"],
            "zone": ["france", "northAmerica", "africa", "southAmerica", "overseasTerritories", "oceania"]
        },
        {
            "code": "dlvg",
            "type": ["home"],
            "zone": ["france", "northAmerica", "africa", "southAmerica", "asia", "europa", "middleEast", "oceania"]
        },
        {
            "code": "upse",
            "type": ["express"],
            "zone": ["northAmerica", "africa", "southAmerica", "asia", "overseasTerritories", "europa", "middleEast", "oceania"]
        },
        {
            "code": "chrp",
            "type": ["express", "relay", "home"],
            "zone": ["france", "northAmerica", "africa", "southAmerica", "asia", "overseasTerritories", "europa", "middleEast", "oceania"]
        },
        {
            "code": "pofr",
            "type": ["home"],
            "zone": ["france", "europa"]
        },
        {
            "code": "copr",
            "type": ["relay", "home"],
            "zone": ["france"]
        },
        {
            "code": "monr",
            "type": ["relay"],
            "zone": ["france", "europa"]
        },
        {
            "code": "tnte",
            "type": ["express", "home"],
            "zone": ["france", "northAmerica", "asia", "europa",]
        },
        {
            "code": "dhle",
            "type": ["express", "home"],
            "zone": ["france", "northAmerica", "africa", "southAmerica", "asia", "overseasTerritories", "europa", "middleEast", "oceania"]
        },
        {
            "code": "sogp",
            "type": ["relay"],
            "zone": ["france", "europa"]
        },
        {
            "code": "sodx",
            "type": ["express", "home"],
            "zone": ["france", "northAmerica", "africa", "southAmerica", "asia", "overseasTerritories", "europa", "middleEast", "oceania"]
        },
        {
            "code": "imxe",
            "type": ["home"],
            "zone": ["france"]
        },
        {
            "code": "dhlf",
            "type": ["other"],
            "zone": ["france", "europa"]
        },
        {
            "code": "lpfr",
            "type": ["home"],
            "zone": ["france"]
        },
    ];

    trigger = document.getElementById('carrier-form');

    constructor() {
        this.initCarriersFilter();
    }

    initCarriersFilter() {
        if (typeof (this.trigger) != 'undefined' && this.trigger != null) {
            this.trigger.addEventListener('change', (event) => {
                this.filterCarriers(this.trigger);
            });

        }
    }

    filterCarriers(form) {
        let selectedCriteria = [];
        let selectedCarriersType = [];
        let selectedCarriers = [];
        let selectedCarriersCode = [];
        let allDomCarriers = document.getElementsByClassName('carrier');
        let noCarriers = document.getElementById('no-carriers');
        let i = 0;

        Array.from(form).forEach(el => {
            selectedCriteria[el.id] = el.value;
        });

        selectedCarriersType = this.allCarriers.filter(carrier => {
            return (
                selectedCriteria['type'] !== null &&
                selectedCriteria['type'] !== 'all' &&
                selectedCriteria['type'] !== ''
            ) ? carrier.type.indexOf(selectedCriteria['type']) > -1 : carrier;
        });

        selectedCarriers = selectedCarriersType.filter(carrier => {
            return (
                selectedCriteria['zone'] !== null &&
                selectedCriteria['zone'] !== 'all' &&
                selectedCriteria['zone'] !== ''
            ) ? carrier.zone.indexOf(selectedCriteria['zone']) > -1 : selectedCarriersType;
        });

        selectedCarriers.filter(carrier => {
            return selectedCarriersCode.push(carrier.code);
        });

        selectedCarriersCode.length === 0 ?
            noCarriers.classList.toggle('hide', false) :
            noCarriers.classList.toggle('hide', true);

        Array.from(allDomCarriers).forEach(domCarrier => {
            domCarrier.removeAttribute('data-order');
            if (selectedCarriersCode.indexOf(domCarrier.id) > -1) {
                i++;
                domCarrier.classList.toggle('hide', false);
                domCarrier.setAttribute('data-order', 'item-' + i);
            } else {
                domCarrier.classList.toggle('hide', true);
                domCarrier.removeAttribute('data-order');
            }
        })
    }
}

export function initCarriersFilter() {
    return new CarriersFilter();
}